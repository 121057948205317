<template>
  <!-- <div class="console" v-html="html"></div> -->
  <div>
    <prism-editor
              class="my-editor"
              v-model="content"
              :highlight="highlighter"
              :readonly=true
            ></prism-editor>
  </div>
</template>

<script>
/* eslint-disable */
import AnsiUp from "ansi_up";
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

const EventSource = NativeEventSource || EventSourcePolyfill;

export default {
  name: "sse-econsole",
  components: {
    PrismEditor,
  },
  data() {
    return {
      // ansi: undefined,
      content: "",
      sseClient: undefined,
    };
  },
  computed: {
    html() {
      return this.content;
      // return this.ansi.ansi_to_html(this.content).replace(/\n/gm, "<br>");
    },
  },
  methods: {
    handelMessage(message){
      this.content = this.content + "\n" + message;
      // this.$forceUpdate();
    },
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
  },
  // beforeMount() {
  //   this.ansi = new AnsiUp();
  // },
  mounted() {
    // console.log("sse-console mounted")
    var _this = this;
    this.content = "";

    var header_auth = `Bearer ${window.localStorage.getItem("access_token")}`;
    var es_url = `${process.env.VUE_APP_API_HOST}/api/eventsource`
    this.sseClient = new EventSource(es_url, {
      headers: {
        Authorization: header_auth,
      },
      // heartbeatTimeout: 120000,
    })
    // console.log(this.sseClient.readyState)
    
    this.sseClient.onmessage = function(event) {
      // console.log(event.data);
      _this.handelMessage(event.data);
    };

    // this.sseClient.onopen = function() {
    //   console.log("connect to eventsource");
    //   console.assert(this.readyState == EventSource.OPEN, "Fail to open eventsource.");
    // };

    this.sseClient.onerror = function() {
      // console.log("EventSource failed.");
      // console.assert(this.readyState == EventSource.CONNECTING, "Fail to connecting eventsource.");
      // 关闭到SSE服务端的连接，避免无效的请求
      this.close();
    };

  },
  updated() {
    this.$el.scrollTop = this.$el.scrollHeight;
    // console.log(this.$el.scrollHeight)
    // console.log(this.$el.scrollTop)
  },
  beforeDestroy(){
    // console.log("disconnect to eventsource");
    this.sseClient.close();
  },
};
</script>

<style lang="scss" scoped>
.console {
  font-family: "roboto mono", monospace;
  // font-family: "DejaVu Sans Mono", monospace;
  font-size: 16px;
  text-align: left;
  background-color: black;
  color: #fff;
  overflow-y: auto;
  padding: 6px 5px 6px 15px;
  white-space: pre;
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;

  color: #ccc;

  // margin: 5px, 30px;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  // overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;

}

</style>
